import React from 'react';
import { Box } from '@chakra-ui/react';
import Cal from '../components/ui/MeetingCalender';
import ManualPageWrapper from '../components/ui/ManualPageWrapper';

const BookMeeting = () => (
  <ManualPageWrapper
    title="Book a meeting or demo"
    textAlign="center"
    seoTitle="SpotCast - Book a Meeting"
    description="The World's Easiest Video Instructions"
  >
    <Box width="100%" mb="auto">
      <Box m="auto" maxWidth="830px">
        <Cal meetingUrl="https://meetings.safer.me/meetings/saferme/introduction-nz?embed=true" />
      </Box>
    </Box>
  </ManualPageWrapper>
);

export default BookMeeting;
